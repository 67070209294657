import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-overland-park-kansas.png'
import image0 from "../../images/cities/scale-model-of-nerman-museum-of-contemporary-art-in-overland-park-kansas.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Overland Park'
            state='Kansas'
            image={image}
            lat='38.889'
            lon='-94.6906'
            attractions={ [{"name": "Nerman Museum of Contemporary Art", "vicinity": "12345 College Blvd, Overland Park", "types": ["art_gallery", "museum", "point_of_interest", "establishment"], "lat": 38.924852, "lng": -94.72682399999997}] }
            attractionImages={ {"Nerman Museum of Contemporary Art":image0,} }
       />);
  }
}